import React from 'react'

export default function PhoneNumber() {
    return (
        <a href='tel:9718884133'>
            971-888-4133
        </a>

    )
}
